<template>
  <div id="user-view">
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <user-bio-panel></user-bio-panel>
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <v-tabs v-model="adminTab" show-arrows class="user-tabs">
          <v-tab v-for="tab in tabs" :key="tab.id" :id="tab.id">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items id="admin-tabs-content" v-model="adminTab" class="mt-5 pa-1">
          <v-tab-item>
            <user-security></user-security>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import { mdiLockOutline } from '@mdi/js';
import UserSecurity from '@/views/shared/users/_partials/UserSecurity.vue';
import UserBioPanel from '@/views/shared/users/UserBioPanel.vue';

export default {
  components: {
    UserBioPanel,
    UserSecurity,
  },
  setup() {
    const adminData = ref(null);
    const adminTab = ref(null);

    const tabs = [{ icon: mdiLockOutline, title: 'Security', id: 'admin-security-tab' }];

    return {
      tabs,
      adminTab,
      adminData,
    };
  },
};
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user';
</style>
